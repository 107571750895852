import styled from 'styled-components';

const StageItemStyle = styled.div`
	--tagGap: var(--sp1-5x);
	--tagPadTB: var(--sp1-5x);
	--tagPadLR: var(--sp2-5x);
	--wrapperPad: var(--sp4x);
	--bottomWrapperFixHeight: var(--sp25x);
	--attrGap: var(--sp6x);
	--attrMTop: var(--sp3x);
	--stageItemBorderRadius: var(--sp4x);
	--stageImageSize: 83.8%;

	--backAttrSize: var(--sp4x);

	position: relative;
	overflow: hidden;
	background-color: var(--whiteColor);

	.top-absolute-wrapper {
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
	}

	.top-absolute-inner-wrapper {
		display: flex;
		flex-wrap: wrap;
		gap: var(--tagGap);
		padding: var(--wrapperPad);
	}

	.tag-title {
		width: fit-content;
		background-color: var(--surfaceColor);
		padding: var(--tagPadTB) var(--tagPadLR);
		border-radius: 2000px;
		text-transform: capitalize;
	}

	.image-cont {
		--proportion: var(--stageImageSize);

		overflow: hidden;
		border-radius: var(--stageItemBorderRadius);
		position: relative;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			background-color: var(--neutral1000Color);
			opacity: 0.25;
			z-index: 1;
			width: 100%;
			height: 100%;
		}

		img {
			clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - (var(--bottomWrapperFixHeight)) + var(--backAttrSize) * 4), 0% calc(100% - var(--bottomWrapperFixHeight) + var(--backAttrSize) * 4));
		}
	}

	.bottom-absolute-wrapper {
		width: 100%;
		height: var(--bottomWrapperFixHeight);
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 3;
	}

	#left {
		width: var(--backAttrSize);
		height: var(--backAttrSize);
		position: absolute;
		top: calc(100% - var(--bottomWrapperFixHeight) - var(--backAttrSize));
		left: 0;
	}

	#right {
		width: var(--backAttrSize);
		height: var(--backAttrSize);
		position: absolute;
		top: calc(100% - var(--bottomWrapperFixHeight) - var(--backAttrSize));
		right: 0;
	}

	.svg-background {
		width: 100%;
		height: var(--bottomWrapperFixHeight);
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: var(--whiteColor);
		transition: transform var(--trTime) ease-out;
		z-index: 2;
	}

	.bottom-absolute-inner-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
		padding: var(--wrapperPad);
	}

	.flex-end-wrapper {
		display: flex;
		flex-wrap: wrap;
		gap: var(--attrGap);
		margin-top: var(--attrMTop);
	}

	.title {
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}

	.title,
	.attr-block * {
		transition: color var(--trTime) ease-out;
	}

	/* //! Hover */
	@media (hover: hover) {
		&:hover {
			img {
				clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
			}

			.svg-background {
				transform: translateY(100%);
			}

			.title,
			.attr-block * {
				color: var(--whiteColor);
			}
		}
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--tagGap: var(--sp1x);
		--tagPadTB: var(--sp1x);
		--tagPadLR: var(--sp2x);
		--wrapperPad: var(--sp3x);
		--bottomWrapperFixHeight: var(--sp20x);
		--attrGap: var(--sp5x);
		--attrMTop: var(--sp2x);
		--stageItemBorderRadius: var(--sp3x);
		--stageImageSize: 84.8%;

		--backAttrSize: var(--sp3x);
	}

	/* //! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--tagGap: var(--sp1x);
		--tagPadTB: var(--sp1x);
		--tagPadLR: var(--sp2x);
		--wrapperPad: var(--sp2-5x);
		--bottomWrapperFixHeight: var(--sp18x);
		--attrGap: var(--sp5x);
		--attrMTop: var(--sp2x);
		--stageItemBorderRadius: var(--sp2-5x);
		--stageImageSize: 88.1%;

		--backAttrSize: var(--sp2-5x);
	}

	/* //! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--tagGap: var(--sp1x);
		--tagPadTB: var(--sp1x);
		--tagPadLR: var(--sp1-5x);
		--wrapperPad: var(--sp2x);
		--bottomWrapperFixHeight: var(--sp16x);
		--attrGap: var(--sp5x);
		--attrMTop: var(--sp1-5x);
		--stageItemBorderRadius: var(--sp2-5x);
		--stageImageSize: 86.4%;

		--backAttrSize: var(--sp2-5x);
	}

	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--tagGap: var(--sp1x);
		--tagPadTB: var(--sp1x);
		--tagPadLR: var(--sp1-5x);
		--wrapperPad: var(--sp2x);
		--bottomWrapperFixHeight: var(--sp16x);
		--attrGap: var(--sp5x);
		--attrMTop: var(--sp1-5x);
		--stageItemBorderRadius: var(--sp2-5x);
		--stageImageSize: 87.3%;

		--backAttrSize: var(--sp2-5x);
	}

	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--tagGap: var(--sp1x);
		--tagPadTB: var(--sp1x);
		--tagPadLR: var(--sp1-5x);
		--wrapperPad: var(--sp2x);
		--bottomWrapperFixHeight: var(--sp16x);
		--attrGap: var(--sp5x);
		--attrMTop: var(--sp1-5x);
		--stageItemBorderRadius: var(--sp2-5x);
		--stageImageSize: 90.3%;

		--backAttrSize: var(--sp2-5x);
	}

	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--tagGap: var(--sp1x);
		--tagPadTB: var(--sp1x);
		--tagPadLR: var(--sp1-5x);
		--wrapperPad: var(--sp2x);
		--bottomWrapperFixHeight: var(--sp15x);
		--attrGap: var(--sp5x);
		--attrMTop: var(--sp1-5x);
		--stageItemBorderRadius: var(--sp2x);
		--stageImageSize: 86.6%;

		--backAttrSize: var(--sp2x);
	}
`;

export default StageItemStyle;
